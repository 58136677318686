import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>
           Under construction.
          </p>
          <p>
            Please visit <a href = "https://cloud.wiznet.io">https://cloud.wiznet.io .</a>
          </p>
        </header>
      </div>
    );
  }
}

export default App;
